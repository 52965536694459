// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-projects-general-2021-04-29-code-post-mdx": () => import("./../../../content/projects/general/2021-04-29-code-post.mdx" /* webpackChunkName: "component---content-projects-general-2021-04-29-code-post-mdx" */),
  "component---content-projects-general-allokationtest-mdx": () => import("./../../../content/projects/general/allokationtest.mdx" /* webpackChunkName: "component---content-projects-general-allokationtest-mdx" */),
  "component---content-projects-news-2021-04-28-first-post-mdx": () => import("./../../../content/projects/news/2021-04-28-first-post.mdx" /* webpackChunkName: "component---content-projects-news-2021-04-28-first-post-mdx" */),
  "component---content-projects-news-2021-05-11-neue-news-mdx": () => import("./../../../content/projects/news/2021-05-11-neue-news.mdx" /* webpackChunkName: "component---content-projects-news-2021-05-11-neue-news-mdx" */),
  "component---content-projects-news-2021-05-30-second-post-mdx": () => import("./../../../content/projects/news/2021-05-30-second-post.mdx" /* webpackChunkName: "component---content-projects-news-2021-05-30-second-post-mdx" */),
  "component---content-projects-thesimplecryptofund-intro-mdx": () => import("./../../../content/projects/thesimplecryptofund/intro.mdx" /* webpackChunkName: "component---content-projects-thesimplecryptofund-intro-mdx" */),
  "component---content-projects-thesimplecryptofund-motivation-mdx": () => import("./../../../content/projects/thesimplecryptofund/motivation.mdx" /* webpackChunkName: "component---content-projects-thesimplecryptofund-motivation-mdx" */),
  "component---content-projects-thesimplecryptofund-portfolioconstruction-mdx": () => import("./../../../content/projects/thesimplecryptofund/portfolioconstruction.mdx" /* webpackChunkName: "component---content-projects-thesimplecryptofund-portfolioconstruction-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

